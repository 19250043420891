import {getUrlVars} from './_get-url-vars';

function getUrlParam( parameter, defaultvalue ) {
	var urlparameter = defaultvalue;
	if ( window.location.href.indexOf( parameter ) > -1 ) {
		urlparameter = getUrlVars()[ parameter ];
	}
	return urlparameter;
}

export { getUrlParam };